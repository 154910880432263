var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    !_vm.showPasswordChallengeSuccess && !_vm.showPasswordChallengeError
      ? _c(
          "div",
          [
            _c("b-alert", {
              attrs: { variant: "danger", show: !!_vm.errorLoginCode },
              domProps: { innerHTML: _vm._s(_vm.formErrorMsg) }
            }),
            _c("h4", { staticClass: "mb-3" }, [
              _vm._v(" " + _vm._s(_vm.$t("passwordChallenge.title")))
            ]),
            _c(
              "b-form",
              {
                on: {
                  submit: function($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.onSubmit.apply(null, arguments)
                  }
                }
              },
              [
                _c(
                  "b-form-group",
                  { attrs: { id: "password-challenge" } },
                  [
                    _c("b-form-input", {
                      attrs: {
                        id: "password-input",
                        name: "password-input",
                        type: "password",
                        placeholder: _vm.$t(
                          "passwordChallenge.placeholderNewPassword"
                        ),
                        state: _vm.validateState("password"),
                        "aria-describedby": "password-live-feedback"
                      },
                      model: {
                        value: _vm.$v.form.password.$model,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.$v.form.password,
                            "$model",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "$v.form.password.$model"
                      }
                    }),
                    _c(
                      "b-form-invalid-feedback",
                      { attrs: { id: "password-live-feedback" } },
                      [
                        !_vm.$v.form.password.required
                          ? _c("div", { staticClass: "error" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("register.requiredPassword")) +
                                  " "
                              )
                            ])
                          : _vm._e(),
                        !_vm.$v.form.password.minLength
                          ? _c("div", { staticClass: "error" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("register.passwordMinLength", {
                                      pwLength:
                                        _vm.$v.form.password.$params.minLength
                                          .min
                                    })
                                  ) +
                                  " "
                              )
                            ])
                          : _vm._e()
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "b-form-group",
                  { attrs: { id: "confirm-new-password-group" } },
                  [
                    _c("b-form-input", {
                      attrs: {
                        id: "confirm-new-password-input",
                        name: "confirm-new-password-input",
                        type: "password",
                        placeholder: _vm.$t(
                          "passwordChallenge.placeholderConfirmNewPassword"
                        ),
                        state: _vm.validateState("confirmNewPassword"),
                        "aria-describedby": "confirm-new-password-live-feedback"
                      },
                      model: {
                        value: _vm.$v.form.confirmNewPassword.$model,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.$v.form.confirmNewPassword,
                            "$model",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "$v.form.confirmNewPassword.$model"
                      }
                    }),
                    _c(
                      "b-form-invalid-feedback",
                      { attrs: { id: "confirm-new-password-live-feedback" } },
                      [
                        !_vm.$v.form.confirmNewPassword.sameAsPassword
                          ? _c("div", { staticClass: "error" }, [
                              _vm._v(" The passwords do not match! ")
                            ])
                          : _vm._e(),
                        !_vm.$v.form.confirmNewPassword.required
                          ? _c("div", { staticClass: "error" }, [
                              _vm._v(" This field can not be empty! ")
                            ])
                          : _vm._e()
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "b-button",
                  {
                    staticClass: "p-3",
                    attrs: {
                      block: "",
                      type: "submit",
                      disabled: _vm.disableSubmitButton,
                      variant: "success"
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("passwordReset.button")) + " ")]
                )
              ],
              1
            )
          ],
          1
        )
      : _vm._e(),
    _vm.showPasswordChallengeSuccess
      ? _c("div", { staticClass: "text-center" }, [
          _c("h4", [_vm._v(_vm._s(_vm.$t("passwordChallenge.confirmTitle")))]),
          _c("p", {
            domProps: {
              innerHTML: _vm._s(_vm.$t("passwordChallenge.confirmMsg"))
            }
          })
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }