<template>
  <div>
    <div v-if="!showPasswordChallengeSuccess && !showPasswordChallengeError">
      <b-alert
        variant="danger"
        :show="!!errorLoginCode"
        v-html="formErrorMsg"
      ></b-alert>
      <h4 class="mb-3"> {{ $t('passwordChallenge.title') }}</h4>
      <b-form @submit.stop.prevent="onSubmit">
        <b-form-group id="password-challenge">
          <b-form-input
            id="password-input"
            name="password-input"
            type="password"
            :placeholder="$t('passwordChallenge.placeholderNewPassword')"
            v-model.trim="$v.form.password.$model"
            :state="validateState('password')"
            aria-describedby="password-live-feedback"
          ></b-form-input>

          <b-form-invalid-feedback id="password-live-feedback">
            <div class="error" v-if="!$v.form.password.required">
              {{ $t('register.requiredPassword') }}
            </div>
            <div class="error" v-if="!$v.form.password.minLength">
              {{
                $t(
                  'register.passwordMinLength',
                  { pwLength: $v.form.password.$params.minLength.min },
                )
              }}
            </div>
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group id="confirm-new-password-group">
          <b-form-input
            id="confirm-new-password-input"
            name="confirm-new-password-input"
            type="password"
            :placeholder="$t('passwordChallenge.placeholderConfirmNewPassword')"
            v-model.trim="$v.form.confirmNewPassword.$model"
            :state="validateState('confirmNewPassword')"
            aria-describedby="confirm-new-password-live-feedback"
          ></b-form-input>

          <b-form-invalid-feedback id="confirm-new-password-live-feedback">
            <div class="error" v-if="!$v.form.confirmNewPassword.sameAsPassword">
              The passwords do not match!
            </div>
            <div class="error" v-if="!$v.form.confirmNewPassword.required">
              This field can not be empty!
            </div>
          </b-form-invalid-feedback>
        </b-form-group>
        <b-button
          class="p-3"
          block
          type="submit"
          :disabled="disableSubmitButton"
          variant="success">
          {{ $t('passwordReset.button') }}
        </b-button>
      </b-form>
    </div>
    <div v-if="showPasswordChallengeSuccess" class="text-center">
      <h4>{{ $t('passwordChallenge.confirmTitle') }}</h4>
      <p v-html="$t('passwordChallenge.confirmMsg')"></p>
    </div>
  </div>
</template>

<script>
import Auth from '@aws-amplify/auth';
import { validationMixin } from 'vuelidate';
import { required, minLength, sameAs } from 'vuelidate/lib/validators';

export default {
  name: 'PasswordReset',
  mixins: [validationMixin],
  props: {
    awsUser: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    form: {
      password: null,
      confirmNewPassword: null,
    },
    errorLoginCode: null,
    loadingIndicator: true,
    passwordChallengeSuccess: false,
    sendPasswordResetEmail: false,
    inputFieldDisabled: false,
    showPasswordChallengeSuccess: false,
    showPasswordChallengeError: false,
  }),
  computed: {
    formErrorMsg() {
      switch (this.errorLoginCode) {
        case 'LimitExceededException':
          return this.$t('passwordReset.limitExceededException');
        case 'auth/user-disabled':
          return this.$t('login.error.userDisabled');
        case 'auth/wrong-password':
          return this.$t('login.error.wrongPassword');
        default:
          return this.errorLoginCode;
      }
    },
    disableSubmitButton() {
      return !!this.$v.form.$invalid;
    },
  },
  async created() {
    try {
      if (this.awsUser.challengeName !== 'NEW_PASSWORD_REQUIRED') {
        console.log('passwordChallenge 1');
        await this.$router.push('/oops-something-went-wrong').catch((err) => {
          throw new Error(`Routing Error occurred! ${err}.`);
        });
      }
    } catch (error) {
      console.error(error);
    }
    this.loadingIndicator = false;
  },
  validations: {
    form: {
      password: {
        required,
        minLength: minLength(8),
      },
      confirmNewPassword: {
        required,
        sameAsPassword: sameAs('password'),
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    validateStateReset(name) {
      const { $dirty, $error } = this.$v.formReset[name];
      return $dirty ? !$error : null;
    },
    async onSubmit() {
      this.errorLoginCode = null;
      this.loadingIndicator = true;
      await this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        this.loadingIndicator = false;
        return;
      }
      try {
        if (this.awsUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
          const loggedUser = await Auth.completeNewPassword(
            this.awsUser,
            this.form.password,
          );
          if (!loggedUser.challengeName) {
            this.showPasswordChallengeSuccess = true;
          } else {
            this.showPasswordChallengeError = true;
            console.log('passwordChallenge 2');
            await this.$router.push('/oops-something-went-wrong').catch((err) => {
              throw new Error(`Routing Error occurred! ${err}.`);
            });
          }
        }
      } catch (err) {
        console.error(err);
      }
      this.loadingIndicator = false;
    },
    showLoader(value) {
      this.loadingIndicator = !!(value);
    },
    resetComponent() {
      this.showPasswordResetSuccess = false;
      this.showPasswordResetError = false;
      this.form.email = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.card-body {
  padding: 35px;
}

small {
  display: block;
}
</style>
